import React, {useContext} from "react";
import {BlockNavigation} from "../../../layout/block";
import './DetailedProjectNavigation.scoped.scss';
import {CustomLink} from "../../../common";
import {RouteDictionary} from "../../../../utils";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {matchPath, useLocation} from "react-router-dom";

const DetailedProjectNavigation = () => {
  const project = useContext(DetailedProjectContext).project;
  const {pathname} = useLocation();
  return (
    <BlockNavigation className="detailed-project-navigation">
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.PROJECT_ECONOMIC_ANALYSIS}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
          explicitActive={matchPath('dashboard/project/:uuid', pathname) !== null}
        >
          Информация о проекте
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.PROJECT_LEGAL_ANALYSIS}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          Информация о заемщике
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.PROJECT_STRATEGY}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          Информация об обеспечении
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.PROJECT_DOCUMENTS}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          Документы
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.PROJECT_STAGES}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          График погашения
        </CustomLink>
      </li>
    </BlockNavigation>
  );
};

export default DetailedProjectNavigation;