import React, {FC, SyntheticEvent, useMemo, useState} from "react";
import {useErrorCallback} from "../../../../hooks";
import {Identification, LegalEntityIdentification, UuidResult} from "../../../../api-client";
import {createIdentificationManager} from "../../../../di";
import IdentificationContext from "../../../context/IdentificationContext";
import {
  IdentificationBlockGroup,
  IdentificationConclusion,
  IdentificationPassport,
  IdentificationPersonal
} from "../blocks";
import {useCombinedData} from "../../../../hooks/identification/useCombinedData";

interface Props {
    onSubmit: (uuid: UuidResult) => void;
    identification: Identification|undefined;

}

const IdentificationLegalEntity: FC<Props> = ({onSubmit, identification}) => {
  const value = useCombinedData();
  const handleError = useErrorCallback();
  const [personalValid, setPersonalValid] = useState(false);
  const [passportValid, setPassportValid] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dataValid = personalValid && passportValid;

  const identificationInput: LegalEntityIdentification = useMemo(() => {
    return {
      person: {
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        snils: value.snils,
      },
      inn: value.inn,
      email: value.email,
      jobPosition: value.jobPosition,
      innLegalEntity: value.innLegalEntity,
      passport: {
        series: value.passportSeries,
        number: value.passportNumber,
        issuedDate: value.passportIssuedDate === null ? (new Date()).toISOString() : value.passportIssuedDate.toISOString(),
        issuedBy: value.passportIssuedBy,
        departmentCode: value.passportDepartmentCode,
        citizenship: value.citizenship,
        firstPassportScans: value.firstPassportScans,
        secondPassportScans: value.secondPassportScans,
        thirdPassportScans: value.thirdPassportScans,
      }
    };
  }, [
    value.firstName,
    value.middleName,
    value.lastName,
    value.inn,
    value.email,
    value.snils,
    value.passportSeries,
    value.passportNumber,
    value.passportIssuedDate,
    value.passportIssuedBy,
    value.passportDepartmentCode,
    value.firstPassportScans,
    value.secondPassportScans,
    value.thirdPassportScans
  ]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      let result;
      const manager = await createIdentificationManager();
      result  = await manager.createLegalEntityIdentification(identificationInput);
      result && onSubmit(result);
    } catch (error: any) {
      await handleError(error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <IdentificationContext.Provider value={value}>
        <IdentificationBlockGroup>
          <IdentificationPersonal isLegalEntity={true} onValidated={(value: boolean) => setPersonalValid(value)} identification={identification} />
          <IdentificationPassport onValidated={(value: boolean) => setPassportValid(value)} passport={identification?.passport} />
          <IdentificationConclusion dataValid={dataValid} loading={loading}/>
        </IdentificationBlockGroup>
      </IdentificationContext.Provider>
    </form>
  );
};

export default IdentificationLegalEntity;