import React, {FC, useState} from "react";
import {IdentificationBlock} from "../../index";
import {
  Button,
  Checkbox,
  ColoredText,
  CustomLink,
  Heading,
  InputErrors,
  PrimaryButton,
  SecondaryHeading
} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../utils";
import {useEffectOnUpdate, useProfileData} from "../../../../../hooks";
import {TextColor} from "../../../../common/typography/texts/ColoredText/ColoredText";

export type IdentificationConclusionErrors = {
    [key: string]: string[];
}

interface Props {
    dataValid: boolean;
    loading: boolean;
}

const IdentificationConclusion: FC<Props> = ({dataValid, loading}) => {
  const requiredElements = [
    'rulesAccepted',
    'riskDeclaration',
    'personalPolicy',
    'electronicDocuments',
    'personalPolicyAccepted',
  ];
  const [value, setValue] = useState<string[]>([]);
  const [errors, setErrors] = useState<IdentificationConclusionErrors>({});
  const profileData = useProfileData();
  const isVerified = () => {
    return profileData.lenderVerified;
  };
  const agreements = [
    {
      coloredText: 'Правила платформы',
      id: 'rulesAccepted',
      route: RouteDictionary.RULES_SERVICE,
      errors: errors.rulesAccepted
    },
    {
      coloredText: 'Декларация о рисках',
      id: 'riskDeclaration',
      route: RouteDictionary.RULES_SERVICE,
      errors: errors.riskDeclaration
    },
    {
      coloredText: 'Политика обработки персональных данных',
      id: 'personalPolicy',
      route: RouteDictionary.RULES_SERVICE,
      errors: errors.personalPolicy
    },
    {
      coloredText: 'Согласие на электронный документооборот',
      id: 'electronicDocuments',
      route: RouteDictionary.RULES_SERVICE,
      errors: errors.electronicDocuments
    },
    {
      coloredText: 'Согласие на обработку персональных данных',
      id: 'personalPolicyAccepted',
      route: RouteDictionary.RULES_SERVICE,
      errors: errors.personalPolicyAccepted
    }
  ];

  const validateBlock = (): boolean => {
    const validationErrors: IdentificationConclusionErrors = {};
    agreements.forEach(({id}) => {
      if (!value.includes(id)) {
        validationErrors[id] = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
      }
    });
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const allElementsPresent = requiredElements.every(element => value.includes(element));

  useEffectOnUpdate(() => {
    validateBlock();
  }, [value]);

  const canSubmit = dataValid && allElementsPresent;

  return (
    <>
      <IdentificationBlock>
        <SecondaryHeading>
          <Heading headingType={HeadingType.H2}>
            Согласие с документами платформы
          </Heading>
        </SecondaryHeading>
        <p>
          Подтверждая согласие с Документами Платформы, вы подтверждаете ознакомление
          и согласие с Правилами Платформы и с локальными актами Платформы:
        </p>
        {!isVerified() && agreements.map(agreement => {
          const {coloredText, route, id, errors} = agreement;

          return (<div className="form__group" key={id}>
            <Checkbox onChange={() =>
              setValue(prevValue =>
                prevValue.includes(id)
                  ? prevValue.filter(item => item !== id)
                  : [...prevValue, id]
              )
            } id={id}>
              <ColoredText color={TextColor.PRIMARY_GREEN}>
                {route &&
                <CustomLink to={route} target={'_blank'}>
                  {coloredText}
                </CustomLink>}
              </ColoredText>
            </Checkbox>
            {errors && <InputErrors errors={errors}/>}
          </div>
          );
        })}
      </IdentificationBlock>
      <IdentificationBlock>
        <SecondaryHeading>
          <Heading headingType={HeadingType.H2}>
            Подтверждения
          </Heading>
        </SecondaryHeading>
        <p>
          Подтверждая данные анкеты с помощью Простой электронной подписи в рамках
          статьи 431.2. “Заверение об обстоятельствах” инвестор подтверждает Оператору
          Платформы следующие факты :
        </p>
        <p>
          — Достоверность данных , указанных на странице Верификации, и своё волеизъявление
          заключить “Договор о содействии в инвестировании” на условиях, указанных в
          Правилах Платформы;
        </p>
        <p>
          — Копии документов , предоставленных в рамках Верификационной Анкеты являются
          достоверными и сделаны лично инвестором с оригинала документа;
        </p>
        <p>
          — Инвестор является налоговым резидентом Российской Федерации;
        </p>
        <p>
          — Инвестор осознаёт тот факт, что инвестирование с использованием Платформы является высоко рискованным и может привести к потере инвестиций в полном объеме,
          и Инвестор понимает и принимает указанные риски;
        </p>
        <div className="form__group">
          <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
            <Button type={"submit"} disabled={loading || (isVerified() ? !dataValid : !canSubmit)}>
              {isVerified() ? "Подтвердить изменения"
                : "Запросить ключ электронной подписи"}
            </Button>
          </PrimaryButton>
        </div>
      </IdentificationBlock>
    </>
    
  )
  ;
};

export default IdentificationConclusion;